<template>
    <section id="equipe" class="equipe padding-y6">
        <div class="container">
            
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-center com-barra margin-b2"><strong>Equipe</strong></h1>
                </div>
            </div>

            <div class="row">
                <div class="pessoa col-md-6 padding-md-x2" v-for="(pessoa, pessoa_i) in equipe" :key="pessoa_i">
                    <h3 class="d-flex align-items-center padding-md-r1 padding-b1 margin-b1">
                        <!--<img :src="pessoa.avatar" v-if="pessoa.avatar" class="float-start margin-r1" />-->
                        <span v-html="pessoa.nome.replaceAll(' ', '<br>')"></span>
                    </h3>
                    <p>{{ pessoa.texto }}</p>
                </div>
            </div>

        </div>
    </section>
</template> 
<script>
export default {
    data: () =>{
        return {
            equipe: [
                {
                    nome: 'André Henrique Althoff',
                    texto: 'Graduado em Direito pela Faculdade de Direito da Universidade Federal de Santa Catarina (UFSC) e mestrando em Direito pela Universidade do Vale do Itajaí (UNIVALI) em cotutela com a Delaware Law School – Widener University. Advogado atuante desde 2005, com ênfase na estruturação de negócios complexos, inclusive com emissão de títulos para captação de recursos, planejamento societário, estruturação e desoneração fiscal de empreendimentos de geração de energia elétrica, bem como com experiência na atuação em demandas envolvendo direito imobiliário e bancário.',
                    avatar: null
                },
                {
                    nome: 'Caetano Dias Corrêa',
                    texto: 'Graduado, mestre e doutor em Direito pela Faculdade de Direito da Universidade Federal de Santa Catarina (UFSC). Professor efetivo do Departamento de Direito e do Programa de Pós-Graduação em Direito da Universidade Federal de Santa Catarina (UFSC). Advogado atuante desde 2005, com ênfase na atuação em contencioso imobiliário, com destaque para o acompanhamento de recursos perante tribunais superiores, planejamento societário/sucessório.',
                    avatar: null
                }
            ]
        }
    }
}
</script>